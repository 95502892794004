<template>
  <div>
    <div class="vx-row">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vx-card
          :title="
            lang.surveyIntegrations.channels.whatsapp.settings.title[
              languageSelected
            ]
          "
          class="overflow-hidden mb-2"
        >
          <div class="vx-row">
            <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
              <WhatsAppOutgoing
                :languageCode.sync="languageCode"
                :elementName.sync="elementName"
                :variables.sync="variables"
                :useVariables.sync="useVariables"
                :fillOneVariable="false"
                :messageType.sync="messageType"
                :namespace.sync="namespace"
                :survey="survey"
              />
              <div style="height: 10px"></div>
              <Confirmation
                :confirmation.sync="confirmation"
                :firstMessage.sync="firstMessage"
                :rejectedMessage.sync="rejectedMessage"
                :resendMessage.sync="resendMessage"
              />
              <div style="height: 10px"></div>
            </vs-col>
            <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
              <Audience
                type="whatsapp"
                v-model="audience"
                @timezoneChange="timezoneChange"
                @expirationChange="expirationChange"
                @expirationMessageChange="expirationMessageChange"
              />
            </vs-col>
          </div>

          <div class="vx-row">
            <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
              <Scheduler
                :showTitle="false"
                :showTimezone="false"
                :showActions="false"
                :visible="false"
                ref="scheduler"
                @onSelect="sendScheduledSurvey"
              />

              <vs-button
                class="mt-3 mb-0 float-left"
                icon-pack="feather"
                icon="icon-send"
                type="filled"
                color="#9ecc38"
                @click="sendSurvey"
              >
                {{
                  lang.surveyIntegrations.channels.whatsapp.settings.buttons
                    .send[languageSelected]
                }}
              </vs-button>

              <vs-button
                class="mt-3 mb-0 ml-3 float-left"
                icon-pack="feather"
                icon="icon-clock"
                type="flat"
                color="#9ecc38"
                @click="openScheduler"
              >
                {{
                  lang.surveyIntegrations.channels.whatsapp.settings.buttons
                    .scheduledSend.title[languageSelected]
                }}
              </vs-button>
            </vs-col>
          </div>
        </vx-card>
      </vs-col>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// import xlsx from '../xlsx'

import services from '../services'
// import Helper from '@/store/Helper'

export default {
  name: 'SurveyChannelWhatsapp',
  props: {
    survey: Object,
    bot: Object,
    integration: Object
  },
  components: {
    WhatsAppOutgoing: () => import('./Components/WhatsAppOutgoing.vue'),
    Confirmation: () => import('./Components/Confirmation.vue'),
    Scheduler: () => import('@/components/scheduler/Scheduler.vue'),
    Audience: () => import('./Components/Audience.vue')
  },
  data() {
    return {
      elementName: '',
      languageCode: '',
      namespace: '',
      useVariables: false,
      fillOneVariable: false,
      messageType: 'HSM',

      confirmation: false,
      firstMessage: '',
      rejectedMessage: '',
      resendMessage: '',

      variables: [],
      newVariable: null,

      audience: [],
      timezone: null,
      expiration: null,
      expirationMessage: null,

      // audienceKeys: [],
      // newAudience: null,

      MAX_AUDIENCE_LENGTH: 1000 * 100, // 100k users
      MAX_AUDIENCE_SIZE: 1024 * 1024 * 100 // 100mb payload size
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    getConfirmationText() {
      let text = `${
        this.lang.surveyIntegrations.channels.whatsapp.messages.confirm.survey[
          this.languageSelected
        ]
      } <${this.survey.service.name}> ${
        this.lang.surveyIntegrations.channels.whatsapp.messages.confirm.bot[
          this.languageSelected
        ]
      } <${this.bot.name}> ${
        this.lang.surveyIntegrations.channels.whatsapp.messages.confirm.channel[
          this.languageSelected
        ]
      } <${this.integration.type}>, `

      if (this.audience.length > 3) {
        text += `${
          this.lang.surveyIntegrations.channels.whatsapp.messages.confirm
            .audience[this.languageSelected]
        } <${this.audience[0].user}, ${this.audience[1].user}, ${
          this.audience[2].user
        }> y ${this.audience.length - 3}+`
      } else {
        text += `${
          this.lang.surveyIntegrations.channels.whatsapp.messages.confirm
            .audience[this.languageSelected]
        } <${this.audience.map(element => element.user).join(', ')}>`
      }

      if (this.elementName) {
        text += `. HSM: <${this.elementName}>`
      }

      return text
    },
    validateSurvey() {
      if (!this.audience.length) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .emptyAudience.title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .emptyAudience.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      if (this.audience.length > this.MAX_AUDIENCE_LENGTH) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.tooManyRecipients.title[
            this.languageSelected
          ],
          text: this.lang.surveyIntegrations.tooManyRecipients.text[
            this.languageSelected
          ].replace('XXX', this.MAX_AUDIENCE_LENGTH),
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      console.log(
        'JSON.stringify(this.audience).length',
        JSON.stringify(this.audience).length
      )
      if (JSON.stringify(this.audience).length > this.MAX_AUDIENCE_SIZE) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.tooManyBytes.title[
            this.languageSelected
          ],
          text: this.lang.surveyIntegrations.tooManyBytes.text[
            this.languageSelected
          ].replace('XXX', this.MAX_AUDIENCE_SIZE),
          color: 'warning',
          position: 'top-right'
        })

        return false
      }

      if (!this.elementName || !this.languageCode) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .missingFields.title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .missingFields.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      if(this.expiration && !this.expirationMessage){
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
                  .missingExpirationMessage.title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
                  .missingExpirationMessage.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      if (this.confirmation) {
        if (
          !this.firstMessage ||
          this.firstMessage.trim().length === 0 ||
          !this.rejectedMessage ||
          this.rejectedMessage.trim().length === 0 ||
          !this.resendMessage ||
          this.resendMessage.trim().length === 0
        ) {
          this.$vs.notify({
            title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .missingConfirmationFields.title[this.languageSelected],
            text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .missingConfirmationFields.text[this.languageSelected],
            color: 'warning',
            position: 'top-right'
          })

          return false
        }
      }

      return true
    },
    sendSurvey() {
      const isValidSurvey = this.validateSurvey()

      if (isValidSurvey) {
        const text = this.getConfirmationText()

        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.confirm
            .title[this.languageSelected],
          text: text,
          acceptText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.accept[this.languageSelected],
          cancelText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.cancel[this.languageSelected],
          accept: async () => this.send()
        })
      }
    },
    sendScheduledSurvey(schedule) {
      const isValidSurvey = this.validateSurvey()

      if (isValidSurvey) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: this.lang.surveyIntegrations.channels.whatsapp.settings
            .scheduleConfirm.title[this.languageSelected],
          text: `${
            this.lang.surveyIntegrations.channels.whatsapp.settings
              .scheduleConfirm.text[this.languageSelected]
          } ${this.$moment(schedule.date).format('DD-MM-YYYY, h:mm:ss A')}?`,
          acceptText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.accept[this.languageSelected],
          cancelText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.cancel[this.languageSelected],
          accept: async () => this.send(schedule)
        })
      }
    },
    saveHsm() {
      const hsm = {
        elementName: this.elementName,
        lang: this.languageCode,
        namespace: this.namespace,
        variables: this.variables,
        useVariables: this.useVariables,
        fillOneVariable: this.fillOneVariable,
        messageType: this.messageType
      }
      localStorage.setItem('hsm', JSON.stringify(hsm))
    },
    getHsm() {
      const hsm = JSON.parse(localStorage.getItem('hsm'))
      this.elementName = hsm && hsm.elementName ? hsm.elementName : '' //'chatclub_southcone_welcome_v1'
      this.languageCode = hsm && hsm.lang ? hsm.lang : this.languageSelected
      this.namespace = hsm && hsm.namespace ? hsm.namespace : ''

      this.messageType = hsm && hsm.messageType ? hsm.messageType : 'HSM'
      this.variables =
        hsm && hsm.variables
          ? hsm.variables
          : this.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form
              .defaultVariables[this.languageSelected]
      this.useVariables = hsm && hsm.useVariables ? hsm.useVariables : false
      this.fillOneVariable =
        hsm && hsm.fillOneVariable ? hsm.fillOneVariable : false
    },
    async send(schedule = null) {
      this.$vs.loading()
      try {
        let data = {}
        if (this.integration && this.integration.type === 'whatsapp_infobip') {
          data = {
            survey: {
              service: this.$route.query.service
            },
            timeOffset: this.timezone,
            config: {
              whatsapp_infobip: {
                type: (this.messageType || 'HSM').toLowerCase(),
                elementName: this.elementName,
                languageCode: this.languageCode,
                // variables: this.variables,
                audience: this.audience
              }
            }
          }
          if (!this.useVariables) {
            data.config.whatsapp_infobip.variables = []
          } else {
            if (!this.fillOneVariable) {
              data.config.whatsapp_infobip.variables = this.variables || []
            }
          }
          if (this.namespace) {
            data.config.whatsapp_infobip.namespace = this.namespace
          }
        } else {
          data = {
            survey: {
              service: this.$route.query.service
            },
            timeOffset: this.timezone,
            config: {
              whatsapp: {
                type: (this.messageType || 'HSM').toLowerCase(),
                elementName: this.elementName,
                languageCode: this.languageCode,
                // variables: this.variables,
                audience: this.audience
              }
            }
          }
          if (!this.useVariables) {
            data.config.whatsapp.variables = []
          } else {
            if (!this.fillOneVariable) {
              data.config.whatsapp.variables = this.variables || []
            }
          }
          if (this.namespace) {
            data.config.whatsapp.namespace = this.namespace
          }
        }
        if (this.confirmation) {
          data.confirmation = {
            active: true,
            firstMessage: this.firstMessage,
            rejectedMessage: this.rejectedMessage,
            resendMessage: this.resendMessage
          }
        }
        if (schedule && schedule.date) {
          data.schedule = schedule.date
        }
        if (this.expiration) {
          data.expiration = this.expiration
          data.expirationMessage = this.expirationMessage
        }
        console.log('NAMESPACE', this.namespace)

        await services.addSurvey(this.bot._id, data)
        this.saveHsm()

        this.$vs.loading.close()
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.success
            .title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.success
            .text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.$router.push({
          path: '/surveys-data',
          query: {
            survey: this.$route.query.survey,
            bot: this.$route.query.bot
          }
        })
      } catch (ex) {
        // TODO: handle error
        this.$vs.loading.close()
        console.error(ex)
        this.$vs.notify({
          title: 'Oops!',
          text: `Parece que ocurrió un error`,
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    openScheduler() {
      this.$refs.scheduler.openPopup()
    },
    timezoneChange(timezone) {
      this.timezone = timezone
    },
    expirationChange(expiration) {
      // le sacamos lo que tenga de offset
      this.expiration = new Date(
        expiration.getTime() - new Date().getTimezoneOffset() * 60 * 1000
      ).toISOString()
    },
    expirationMessageChange(expirationMessage) {
      this.expirationMessage = expirationMessage
    }
    // downloadExample() {
    //   window.open('/survey_template_wpp.xlsx', '_blank')
    // },
    // removeAudience(index) {
    //   this.audience.splice(index, 1)
    //   if (!this.audience.length) {
    //     this.audienceKeys = []
    //   }
    // },
    // addAudience() {
    //   const aux = this.newAudience.replace(/^\D+/g, '')
    //   if (aux && aux.length > 0) {
    //     this.audience.push({ user: aux })
    //     this.newAudience = ''
    //     this.audienceKeys.indexOf('user') === -1 &&
    //       this.audienceKeys.push('user')
    //   }
    // },

    // async handleDrop(e) {
    //   try {
    //     const result = await xlsx.handleDrop(e)
    //     if (!result || !result.tickets) {
    //       this.$vs.notify({
    //         title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //           .dragAndDrop.title[this.languageSelected],
    //         text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //           .dragAndDrop.text[this.languageSelected],
    //         color: 'danger',
    //         position: 'top-right'
    //       })
    //       return
    //     }
    //     const _createdAt = new Date()
    //     const badNumbers = []
    //     const isNumber = /^\d+$/

    //     const xlsAudiences = result.tickets
    //       .filter(element => element.user)
    //       .map(element => {
    //         if (!isNumber.test(element.user)) {
    //           badNumbers.push(element.user)
    //           return {}
    //         }
    //         let replacedElems = {}
    //         Object.keys(element).forEach(key => {
    //           const newKey = Helper.replaceSpecialCharacters(key)
    //           replacedElems[newKey] = element[key]
    //         })

    //         return {
    //           ...replacedElems,
    //           _createdAt
    //         }
    //       })
    //     // .filter(element => element.user)
    //     this.audienceKeys =
    //       xlsAudiences.length > 0
    //         ? Object.keys(xlsAudiences[0]).filter(key => key !== '_createdAt')
    //         : []
    //     this.audience = this.audience.concat(xlsAudiences)
    //     if (this.audience.length === 0) {
    //       this.$vs.notify({
    //         title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //           .missingAudience.title[this.languageSelected],
    //         text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //           .missingAudience.text[this.languageSelected],
    //         color: 'warning',
    //         position: 'top-right'
    //       })
    //     }
    //     if (badNumbers.length > 0) {
    //       this.$vs.notify({
    //         title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //           .invalidEmail.title[this.languageSelected],
    //         text: `${
    //           this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //             .invalidEmail.list[this.languageSelected]
    //         }; ${badNumbers.slice(0, 3).join(', ')} ${
    //           this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //             .invalidEmail.and[this.languageSelected]
    //         } ${Math.max(badNumbers.length - 3, 0)} ${
    //           this.lang.surveyIntegrations.channels.whatsapp.messages.error
    //             .invalidEmail.more[this.languageSelected]
    //         }`,
    //         color: 'warning',
    //         position: 'top-right'
    //       })
    //     }
    //     // console.log('audience', JSON.stringify(this.audience, undefined, 2))
    //   } catch (error) {
    //     // TODO: handle error
    //     this.$vs.notify({
    //       title: 'Oops!',
    //       text: 'Parece que ocurrió un error',
    //       color: 'danger',
    //       position: 'top-right'
    //     })
    //   }
    // },
    // handleDragover: xlsx.handleDragover
  },
  mounted() {
    this.variables = this.lang.surveyIntegrations.channels.whatsapp.settings.hsm.form.defaultVariables[
      this.languageSelected
    ]
    this.firstMessage = this.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form.firstMessage.placeholder[
      this.languageSelected
    ]
    this.rejectedMessage = this.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form.rejectedMsg.placeholder[
      this.languageSelected
    ]
    this.resendMessage = this.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form.resendMsg.placeholder[
      this.languageSelected
    ]
    window.scrollTo(0, document.body.scrollHeight)
  },
  created() {
    this.getHsm()
  }
}
</script>

<style scoped>
.custom-label {
  font-size: 12px;
  color: grey;
}

html {
  scroll-behavior: smooth;
}

.text-white-2:hover {
  color: white;
}

/* .drag-file {
  background-image: linear-gradient(to right, #cecccc, transparent);
  opacity: 0.5;
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
}

.drag-file:hover {
  background-image: linear-gradient(to right, #ff9f38, transparent);
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  transition: opacity 200ms linear;
} */
</style>
